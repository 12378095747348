import {
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
  type SerializeFrom,
  type MetaFunction,
} from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  LiveReload,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { useEffect, type ReactNode } from "react";
import "~/tailwind.css";
import { Layout } from "./components/Layout";
import { env } from "./lib/env.server";
import { AlertDialogProvider } from "./components/ui/AlertDialogProvider";
import { commitSession, getSession } from "./lib/session.server";
import { useToast } from "./components/ui/use-toast";
import { Toaster } from "./components/ui/toaster";
import { edgeAuthenticator } from "./lib/auth.edge.server";
import { useReplicachePoke } from "./utils/replicache.client";
import { useRegisterSW } from "virtual:pwa-register/react";
import { ClientOnly } from "remix-utils/client-only";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  // TODO: Make the flash message its own session storage
  const session = await getSession(request.headers.get("Cookie"));
  const flashMessage = session.get("flashMessage");
  const user = await edgeAuthenticator.isAuthenticated(request);
  return json(
    {
      ENV: {
        VERCEL_ANALYTICS_ID: env.VERCEL_ANALYTICS_ID,
        WEB_PUSH_PUBLIC_KEY: env.WEB_PUSH_PUBLIC_KEY,
        UPLOAD_PRESET: env.CLOUDINARY_UPLOAD_PRESET,
        CLOUDINARY_APP: env.CLOUDINARY_APP,
      },
      flashMessage,
      user,
    },
    {
      headers: {
        "Set-Cookie": await commitSession(session),
      },
    }
  );
};

export const links: LinksFunction = () => [
  { rel: "apple-touch-icon", href: "/icons/apple-touch-icon.png" },
  // { rel: "manifest", href: "/manifest.webmanifest" },
];

export const meta: MetaFunction = () => {
  return [
    {
      charSet: "utf-8",
    },
    {
      title: "SimmerDown Recipes",
    },
    {
      name: "description",
      content: "Tasty recipes in the best recipe viewer ever.",
    },
    {
      name: "viewport",
      content: "width=device-width,initial-scale=1,user-scalable=no",
    },
    {
      name: "apple-mobile-web-app-capable",
      content: "yes",
    },
    {
      name: "theme-color",
      content: "#0c0a09",
    },
  ];
};

declare global {
  interface Window {
    ENV: SerializeFrom<typeof loader>["ENV"];
    USER: SerializeFrom<typeof loader>["user"];
  }
}

function AppWrapper({ children }: { children: ReactNode }) {
  return (
    <html lang="en" className="overflow-x-clip">
      <head>
        <Meta />
        <Links />
      </head>
      <body className="bg-stone-100 text-stone-900 dark:bg-acadia-950  dark:text-stone-100 font-sans overflow-x-clip">
        <AlertDialogProvider>
          {children}
          <Toaster />
        </AlertDialogProvider>

        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}

export default function App() {
  const data = useLoaderData<typeof loader>();
  useReplicachePoke?.();

  return (
    <AppWrapper>
      <Layout user={data.user}>
        <Outlet />
      </Layout>
      <FlashMessage message={data.flashMessage} />
      <ClientOnly>{() => <Pwa />}</ClientOnly>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(data?.ENV || {})};
          window.USER = ${JSON.stringify(data?.user || null)}`,
        }}
      />
    </AppWrapper>
  );
}

const Pwa = () => {
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegistered(r) {
      console.info("SW Registered: ", r);
    },
    onRegisterError(error) {
      console.info("SW registration error", error);
    },
  });

  return null;
};

export function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  if (isRouteErrorResponse(error)) {
    return (
      <AppWrapper>
        <Layout>
          <h1 className="font-serif font-medium tracking-wide text-4xl text-center">
            {error.status === 404
              ? "Page Not Found"
              : "Something unexpected has happened"}
          </h1>
          <p className="max-w-prose mx-auto mt-16">
            Maybe try visiting another page?
          </p>
        </Layout>
      </AppWrapper>
    );
  }

  let errorMessage = "Unknown Error";
  if (error instanceof Error) {
    errorMessage = error.message;
  }

  return (
    <AppWrapper>
      <Layout>
        <h1 className="font-serif font-medium tracking-wide text-4xl text-center">
          Server Error
        </h1>

        <p className="max-w-prose mx-auto mt-16">
          Something unexpected happened, and it's our fault.
        </p>
        <p className="font-mono max-w-prose mx-auto mt-16">{errorMessage}</p>
      </Layout>
    </AppWrapper>
  );
}

function FlashMessage({ message }: { message: string | undefined }) {
  const { toast } = useToast();

  useEffect(() => {
    if (message) {
      toast({
        title: message,
      });
    }
  }, [message, toast]);

  return null;
}
