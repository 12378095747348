import { useLocation } from "@remix-run/react";
import {
  Apple,
  Carrot,
  ChefHat,
  Croissant,
  Download,
  LogOut,
  PlusCircle,
  User,
  Utensils,
} from "lucide-react";
import { type ReactNode } from "react";
import { Link } from "~/components/ui/Link";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "~/components/ui/NavigationMenu";
import type { AuthSession } from "~/lib/auth.server";
import { cn } from "~/lib/utils";

export const navigation = {
  Recipes: { link: "/", icon: <Utensils /> },
  "Meal Plans": { link: "/plans", icon: <ChefHat /> },
  Groceries: { link: "/groceries", icon: <Apple /> },
  Pantry: { link: "/pantry", icon: <Carrot /> },
};

export function Layout({
  children,
  user,
}: {
  children: ReactNode;
  user?: AuthSession | null;
}) {
  return (
    <div className="relative flex flex-col h-full min-h-screen">
      <header className="w-full flex justify-end sm:justify-center lg:justify-evenly items-center gap-8 pt-8 pb-4 sm:pt-10 sm:pb-6 mx-auto px-4">
        <div className="w-[80px]"></div>
        {Object.entries(navigation)
          .slice(0, 2)
          .map(([name, nav]) => (
            <Link
              key={name}
              to={nav.link}
              prefetch="intent"
              variant="header"
              className="hidden lg:block"
            >
              {name}
            </Link>
          ))}

        <Link to="/" className="flex-1">
          <div className="flex justify-center items-center text-acadia-950 dark:text-stone-100">
            <Croissant
              className="w-6 h-6 sm:w-8 sm:h-8 mr-4"
              aria-label="Simmerdown"
            />
            <h1 className="hidden sm:block sm:text-4xl font-serif tracking-wide">
              Simmerdown
            </h1>
          </div>
        </Link>
        {Object.entries(navigation)
          .slice(2, 4)
          .map(([name, nav]) => (
            <Link
              key={name}
              to={nav.link}
              prefetch="intent"
              variant="header"
              className="hidden lg:block"
            >
              {name}
            </Link>
          ))}
        {user ? (
          <NavigationMenu className="w-[80px] flex-grow-0" side="right">
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  <User className="w-8 h-8 p-2 rounded-full bg-stone-300 dark:bg-stone-700 " />
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="flex flex-col">
                    <li>
                      <NavigationMenuLink asChild>
                        <Link
                          className="w-full flex gap-2 py-2 px-4 hover:bg-stone-300 hover:dark:bg-stone-700 rounded whitespace-nowrap"
                          to="/recipe/new"
                          prefetch="intent"
                        >
                          <PlusCircle /> New Recipe
                        </Link>
                      </NavigationMenuLink>
                    </li>
                    <li>
                      <NavigationMenuLink asChild>
                        <Link
                          className="w-full flex gap-2 py-2 px-4 hover:bg-stone-300 hover:dark:bg-stone-700 rounded whitespace-nowrap"
                          to="/import"
                          prefetch="intent"
                        >
                          <Download /> Import Recipe
                        </Link>
                      </NavigationMenuLink>
                    </li>
                    <li>
                      <NavigationMenuLink asChild>
                        <Link
                          className="w-full flex gap-2 py-2 px-4 hover:bg-stone-300 hover:dark:bg-stone-700 rounded whitespace-nowrap"
                          to="/logout"
                        >
                          <LogOut /> Logout
                        </Link>
                      </NavigationMenuLink>
                    </li>
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
        ) : (
          <div className="w-[80px]" />
        )}
      </header>
      <main className="flex-1 py-4 overflow-x-clip">{children}</main>
      <footer className="min-h-[4.5rem] pointer-events-none">
        <div className="hidden lg:block text-center uppercase tracking-widest text-stone-600 dark:text-stone-400 py-4">
          <small>Copyright &copy; 2024 Simmerdown</small>
        </div>
        <div className="lg:hidden flex justify-between items-center bg-stone-200 dark:bg-stone-800 w-full px-4 sm:px-16 md:px-32 pb-2 pt-4 fixed bottom-0 z-30 pointer-events-auto">
          {Object.entries(navigation).map(([name, nav]) => (
            <NavLink to={nav.link} key={nav.link}>
              {nav.icon}
              {name}
            </NavLink>
          ))}
        </div>
      </footer>
    </div>
  );
}

function NavLink({ to, children }: { to: string; children: ReactNode }) {
  const pathname = useLocation().pathname;
  const isActive =
    to === "/"
      ? pathname === to || pathname.startsWith("/recipe")
      : pathname.startsWith(to);
  return (
    <Link
      to={to}
      className={cn(
        "h-11 w-11 text-sm flex flex-col whitespace-nowrap justify-center items-center cursor-pointer",
        { "text-jaffa-600 dark:text-jaffa-300": isActive }
      )}
      prefetch="intent"
    >
      {children}
    </Link>
  );
}
